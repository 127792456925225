(function () {
    if (jQuery && jQuery.fn && jQuery.fn.select2 && jQuery.fn.select2.amd) var e = jQuery.fn.select2.amd;

    return e.define('select2/i18n/sk', [], () => {
        const e = {
            2(e) {
                return e ? 'dva' : 'dve';
            },
            3() {
                return 'tri';
            },
            4() {
                return 'štyri';
            },
        };
        return {
            errorLoading() {
                return 'Výsledky sa nepodarilo načítať.';
            },
            inputTooLong(t) {
                const n = t.input.length - t.maximum;

                if (n === 1) {
                    return 'Zadajte o jeden znak menej';
                } if (n >= 2 && n <= 4) {
                    return `Zadajte o ${e[n](!0)} znaky menej`;
                }
                return `Zadajte o ${n} znakov menej`;
            },
            inputTooShort(t) {
                const n = t.minimum - t.input.length;
                if (n === 1) {
                    return 'Zadajte ešte jeden znak';
                } if (n <= 4) {
                    return `Zadajte aspoň ${e[n](!0)} znaky`;
                }
                return `Zadajte aspoň ${n} znakov`;
            },
            loadingMore() {
                return 'Načítanie ďalších výsledkov…';
            },
            maximumSelected(t) {
                if (t.maximum === 1) {
                    return 'Môžete zvoliť len jednu položku';
                } if (t.maximum >= 2 && t.maximum <= 4) {
                    return `Môžete zvoliť najviac ${e[t.maximum](!1)} položky`;
                }
                return `Môžete zvoliť najviac ${t.maximum} položiek`;
            },
            noResults() {
                return 'Nenašli sa žiadne položky';
            },
            searching() {
                return 'Vyhľadávanie…';
            },
        };
    }), { define: e.define, require: e.require };
}());
