function createFlashMessage() {
    function skeleton(message, type) {
        return `<div class="alert alert-${type} alert-dismissible">${message}</div>`;
    }

    function createMessageTrigger() {
        const flashMessageWrapperSelector = '.js-flash-messages-wrapper-base';
        $(document).on('flash-message.show', '.js-flash-message-target', (event, params) => {
            const previous = $(flashMessageWrapperSelector).html();
            const { message } = params.data;
            const { type } = params.data;
            $(flashMessageWrapperSelector).html(previous + skeleton(message, type));
        });
    }

    function clearMessagesTrigger() {
        const flashMessageWrapperSelector = '.js-flash-messages-wrapper-base';
        $(document).on('flash-message.clear', '.js-flash-message-target', () => {
            $(flashMessageWrapperSelector).html('');
        });
    }

    function init() {
        createMessageTrigger();
        clearMessagesTrigger();
    }

    return {
        init,
    };
}

module.exports = createFlashMessage();
