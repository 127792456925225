function facebookInit() {
    function login() {
        const selector = '.js-facebook-login';

        $(selector).click(() => {
            FB.getLoginStatus((response) => {
                const loginUrl = $('#js-facebook-links').attr('data-url');

                if (response.status === 'connected') {
                    document.location = loginUrl;
                } else {
                    FB.login((loginResponse) => {
                        if (loginResponse.authResponse) {
                            document.location = loginUrl;
                        }
                    }, { scope: 'email' });
                }
            });
        });
    }

    function init() {
        window.fbAsyncInit = function fbAsyncInit() {
            const appId = $('#js-facebook-links').attr('data-app-id');
            FB.init({
                appId,
                xfbml: true,
                cookie: true,
                status: true,
            });
        };

        // Load the SDK asynchronously
        (function loadSDK(d, s, id) {
            if (d.getElementById(id)) {
                return;
            }
            const fjs = d.getElementsByTagName(s)[0];
            const jss = d.createElement(s);

            jss.id = id;
            jss.src = '//connect.facebook.net/en_US/all.js';
            fjs.parentNode.insertBefore(jss, fjs);
        }(document, 'script', 'facebook-jssdk'));

        login();
    }

    return {
        init,
    };
}

module.exports = facebookInit();
