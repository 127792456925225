const slugify = require('slugify');
require('select2/dist/js/select2.full');
require('./sk.js');

let city = null;
let categories = [];
let audiences = [];
let types = [];
let attractiveness = [];
let timeChoice = null;

function selectize() {
    $.fn.select2.defaults.set('theme', 'bootstrap-5');

    // PLACE SELECTOR
    function initPlace() {
        const $select = $('.js-place');
        const $filler = $('.js-fill-place');

        $select.select2({
            theme: "bootstrap-5",
            language: 'sk',
            width: 'resolve',
            placeholder: $select.attr('data-placeholder'),
            minimumInputLength: 3,
            ajax: {
                url: $select.attr('data-url'),
                dataType: 'json',
                processResults(data) {
                    return {
                        results: $.map(data.items, (val) => ({
                            id: slugify(val.urlArgument, {lower: true}), text: val.text,
                        })),
                    };
                },
            },
        });

        $select.on('select2:select', (e) => {
            const {data} = e.params;

            if ($select.hasClass('js-iframe-place-select')) {
                const iframeTextArea = $('#js-iframe-html');
                const textPart1 = iframeTextArea.data('text-part-1');
                const textPart2 = iframeTextArea.data('text-part-2');
                const cityName = data.id;
                iframeTextArea.val(textPart1 + cityName + textPart2);

                const copyButtonSelector = '.js-copy-to-clipboard';
                $(copyButtonSelector).each(function () {
                    $(this).html($(this).attr('data-original-title'));
                });
            }
            city = data.id;
        });

        $filler.click(function fillerClick() {
            const cityName = $(this).data('city');
            const placeholder = $(this).text();
            $select.select2('trigger', 'select', {
                data: {
                    id: cityName, text: placeholder,
                },
            });
        });
    }

    // TYPE SELECTOR
    function initType() {
        const $select = $('.js-type');
        const $fillers = $('.js-fill-category');

        $select.select2({
            language: 'sk',
            placeholder: $select.attr('data-placeholder'),
            minimumInputLength: 3,
            ajax: {
                url: $select.attr('data-url'),
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
                processResults(data) {
                    return {
                        results: $.map(data.items, (val) => ({
                            id: val.slug, text: val.text, context: val.context,
                        })),
                    };
                },
            },
        });

        $select.on('select2:select', (e) => {
            audiences = null;
            categories = null;
            types = null;

            const {data} = e.params;
            const {context} = data;

            switch (context) {
                case 'audience':
                    audiences = data.id;
                    break;
                case 'category':
                    categories = data.id;
                    break;
                case 'event type':
                    types = data.id;
                    break;
            }
        });

        $fillers.click(function fillerCategoryClick(event) {
            event.preventDefault();
            const slug = $(this).data('category-slug');
            const placeholder = $(this).text();
            $select.select2('trigger', 'select', {
                data: {
                    text: placeholder, id: slug, context: 'event type',
                },
            });
        });
    }

    function initIndexSearch() {
        const searchSelector = '#js-index-search';
        const searchUrl = $('.js-select-search').data('search-url');

        $(searchSelector).click(() => {
            let url = '';

            if (city != null) {
                if (city.length > 0) {
                    url = `${url}/${city}`;
                } else {
                    url = `${url}/sk`;
                }
            } else {
                url = `${url}/sk`;
            }

            if (audiences != null) {
                if (audiences.length > 0) {
                    url = `${url}/${audiences}`;
                }
            }

            if (categories != null) {
                if (categories.length > 0) {
                    url = `${url}/${categories}`;
                }
            }

            if (types != null) {
                if (types.length > 0) {
                    url = `${url}/${types}`;
                }
            }

            let delimiter = '?';

            if (timeChoice != null) {
                if (timeChoice.length > 0) {
                    url = `${url}?time=${timeChoice}`;
                    delimiter = '&';
                }
            }

            const mobileAttr = $('.js-select-search').attr('data-mobile');

            // For some browsers, `attr` is undefined; for others,
            // `attr` is false.  Check for both.
            if (typeof mobileAttr !== typeof undefined && mobileAttr !== false) {
                url = `${url}${delimiter}mobile=1`;
            }
            window.location.href = searchUrl + url;
        });
    }

    function iframeAjaxSearch() {
        const searchSelector = '#js-index-ajax-search';

        $(searchSelector).click((event) => {
            $(event.target).prepend('<span id="spinner" class="spinner-border mr-1" role="status" aria-hidden="true"></span>');
            $(event.target).attr('disabled', true);

            const resultSelector = '.js-iframe-search-result';
            const searchButton = $('#js-index-ajax-search');
            let searchUrl = searchButton.data('search-url');

            if (searchButton.data('filter-city')) {
                searchUrl = `${searchUrl}/${searchButton.data('filter-city')}`;
            }

            if (audiences != null) {
                if (audiences.length > 0) {
                    searchUrl = `${searchUrl}/${audiences}`;
                }
            }

            if (categories != null) {
                if (categories.length > 0) {
                    searchUrl = `${searchUrl}/${categories}`;
                }
            }

            if (types != null) {
                if (types.length > 0) {
                    searchUrl = `${searchUrl}/${types}`;
                }
            }

            if (timeChoice != null) {
                if (timeChoice.length > 0) {
                    searchUrl = `${searchUrl}?time=${timeChoice}`;
                }
            }

            $.ajax({
                url: searchUrl,
            }).done((data) => {
                $('#spinner').remove();
                $(event.target).attr('disabled', false);
                $(resultSelector).html(data);
            });
        });
    }

    // SUBJECT SELECTOR
    function initSubject() {
        const $select = $('.js-subject');

        $select.each(function createSubjectSelect2() {
            const $this = $(this);
            $this.select2({
                language: 'sk',
                minimumInputLength: 3,
                required: $(this).attr('required'),
                ajax: {
                    url: $this.attr('data-url'),
                    dataType: 'json',
                    processResults(data) {
                        return {
                            results: $.map(data.items, (val) => ({
                                id: val.id, text: val.text,
                            })),
                        };
                    },
                },
            });
        });
    }

    function initSearch() {
        const $select = $('.js-select-search');
        const eventUrl = $select.data('event-url');
        const searchUrl = $select.data('search-url');

        $select.select2({
            language: 'sk',
            minimumInputLength: 3,
            width: '80%',
            placeholder: $select.attr('data-placeholder'),
            ajax: {
                url: $select.attr('data-url'),
                dataType: 'json',
                contentType: 'application/json; charset=utf-8',
                headers: {
                    Accept: $select.attr('data-accept'),
                },
                data(params) {
                    return {
                        query: params.term,
                    };
                },
                processResults: function (data) {
                    // Transforms the top-level key of the response object from 'items' to 'results'
                    return data;
                }
            },
        });

        $select.on('select2:select', (e) => {
            const {data} = e.params;

            const mobileAttr = $select.attr('data-mobile');
            if (typeof mobileAttr !== typeof undefined && mobileAttr !== false) {
                data.urlArgument = `${data.urlArgument}?mobile=1`;
            }

            if (data.type === 'event') {
                window.location.href = `${eventUrl}/${data.id}/${data.urlArgument}`;
            } else if (data.type === 'city') {
                window.location.href = `${searchUrl}/${data.urlArgument}`;
            } else {
                window.location.href = `${searchUrl}/sk/${data.urlArgument}`;
            }
        });
    }

    function createModalSelect2InitTrigger() {
        const selector = '.add-another-entity-into-collection-widget, .js-init-modal-trigger, .js-set-notification';
        $(document).on('modal-select2.init', selector, () => {
            const $select = $('#modal_skeleton').find('.js-subject');
            $select.each(function createSubjectSelect2() {
                const $this = $(this);
                $this.select2({
                    dropdownParent: $('#modal_skeleton'),
                    required: $(this).attr('required'),
                    language: 'sk',
                    minimumInputLength: 3,
                    ajax: {
                        url: $this.attr('data-url'),
                        dataType: 'json',
                        processResults(data) {
                            return {
                                results: $.map(data.items, (val) => ({
                                    id: val.id, text: val.text,
                                })),
                            };
                        },
                    },
                });
            });
        });
    }

    function setCategories() {
        const parentSelector = '.js-parent-category';
        const childSelector = '.js-child-category';
        const attractivenessSelector = '.js-attractiveness';
        audiences = [];
        categories = [];
        types = [];
        attractiveness = [];

        $(`${parentSelector}, ${childSelector}`).each(function () {
            if ($(this).is(':checked')) {
                switch ($(this).data('context')) {
                case 'audience':
                    audiences[$(this).val()] = $(this).data('slug');
                    break;
                case 'category':
                    categories[$(this).val()] = $(this).data('slug');
                    break;
                case 'event-type':
                    types[$(this).val()] = $(this).data('slug');
                    break;
                }
            }
        });
        $(`${attractivenessSelector}`).each(function () {
            if ($(this).is(':checked')) {
                attractiveness[$(this).val()] = $(this).data('slug');
            }
        });
    }

    function eventFormSelectParentCategory() {
        const selector = '.js-select-parent';
        const $checkBoxes = $(selector).find("input[type='checkbox']");

        $(document).on('click', $checkBoxes, () => {
            $(this).click((event) => {
                if ($(event.target).is(':checked')) {
                    $(event.target).parents('ul.root>li').children('label').find("input[type='checkbox']")
                        .prop('checked', true);
                } else {
                    $(event.target).parents('li').find("ul input[type='checkbox']").prop('checked', false);
                }
            });
        });
    }

    function unpackCategoryChildren() {
        const selector = '.js-parent-category';
        const selectorChevron = '.js-parent-category-chevron';

        $(selector).change((event) => {
            const childrenSelector = $(event.target).attr('data-children');

            if ($(event.target).is(':checked')) {
                $(childrenSelector).find('input[type="checkbox"]').prop('checked', false);
                $($(event.target).attr('data-chevron')).addClass('fa-chevron-up');
                $($(event.target).attr('data-chevron')).removeClass('fa-chevron-down');
            }
            if ($($(event.target).attr('data-chevron')).hasClass('fa-chevron-up')) {
                $(childrenSelector).show();
            }
            setCategories();
        });

        $(selectorChevron).click((event) => {
            $(event.target).toggleClass('fa-chevron-down fa-chevron-up');
            const childrenSelector = $(event.target).attr('data-children');
            if ($(event.target).hasClass('fa-chevron-up')) {
                $(childrenSelector).show();
            } else {
                $(childrenSelector).hide();
            }
        });
    }

    function initUnpackCategoryChildren() {
        const selector = '.js-init-unpack';
        $($(selector).attr('data-children')).show();
    }

    function uncheckParentCategory() {
        const selector = '.js-child-category';

        $(selector).change((event) => {
            if ($(event.target).is(':checked')) {
                $($(event.target).attr('data-parent-category')).prop('checked', false);
            }
            setCategories();
        });
    }

    function editSubject() {
        const selector = '.js-edit-subject';

        $(selector).click((event) => {
            event.preventDefault();
            window.location = $(event.target).attr('data-url');
        });
    }

    function setTimeTrigger() {
        $(document).on('time-choice.set', (event, params) => {
            timeChoice = params.data.time;
        });
    }

    function initValues() {
        setCategories();
        const citySelector = '#city-id';

        if ($(citySelector).length) {
            city = slugify($(citySelector).data('city'), {lower: true});
        }
    }

    function search() {
        let categoriesUrl = '';
        let attractivenessUrl = '';
        let completeUrl = '';
        for (const key in audiences) {
            categoriesUrl += `${audiences[key]}_`;
        }

        for (const key in categories) {
            categoriesUrl += `${categories[key]}_`;
        }

        for (const key in types) {
            categoriesUrl += `${types[key]}_`;
        }
        for (const key in attractiveness) {
            attractivenessUrl += `${attractiveness[key]}_`;
        }
        if (categoriesUrl.length > 0) {
            categoriesUrl = categoriesUrl.substring(0, categoriesUrl.length - 1);
        }
        if (attractivenessUrl.length > 0) {
            attractivenessUrl = attractivenessUrl.substring(0, attractivenessUrl.length - 1);
        }

        if (city != null) {
            if (city.length > 0) {
                completeUrl += `/${city}`;
            } else {
                completeUrl += '/sk';
            }
        } else {
            completeUrl += '/sk';
        }

        if (categoriesUrl.length > 0) {
            completeUrl += `/${categoriesUrl}`;
        } else {
            completeUrl += '/vsetky-kategorie';
        }
        if (attractivenessUrl.length > 0) {
            completeUrl += `/${attractivenessUrl}`;
        }

        let delimiter = '?';
        if (timeChoice != null) {
            completeUrl += `?time=${timeChoice}`;
            delimiter = '&';
        }
        if ($('input[name=sort]:checked').val() !== undefined) {

            completeUrl += `${delimiter}sort=${$('input[name=sort]:checked').val()}`;
            delimiter = '&';
        }
        const mobileAttr = $('#event-filter-form').attr('data-mobile');

        if (typeof mobileAttr !== typeof undefined && mobileAttr !== false) {
            completeUrl += `${delimiter}mobile=1`;
        }
        window.location.href = $('#event-filter-form').data('search-url') + completeUrl;
    }

    function searchEvents() {
        const sortSelector = 'sort';
        const buttonSelector = '#js-search-page-button';

        $(buttonSelector).click((event) => {
            event.preventDefault();
            search();
        });

        $(`input[type=radio][name=${sortSelector}]`).change(() => {
            search();
        });
    }

    function initMobileBackButton() {
        const selector = '#js-mobile-back';

        $(selector).click(() => {
            window.history.back();
        });
    }

    function initBasic() {
        $('.js-select2-basic').select2();
    }

    function searchOnSelect() {
        const selector = '#js-select-search';
        const elementSelector = '.js-searchable-by-select';
        const textPlacementSelector = '#js-select-search-text';
        const searchUrl = $(selector).data('search-url');
        const linkSelector = '#js-select-search-link';
        const closeSelector = '#js-select-search-close';

        if ($(elementSelector).length === 0) {
            return;
        }
        $(elementSelector).on('mouseup', () => {
            const selection = document.getSelection();
            const selectionText = selection.toString().trim();

            if (selectionText.length < 3 || selectionText.length > 50) {
                $(selector).hide();
                return;
            }
            let showText = selectionText;
            if (showText.length > 30) {
                showText = `${showText.substring(0, 27)}...`;
            }
            const rect = selection.getRangeAt(0).getBoundingClientRect();
            const left = Math.max(rect.left - ($(selector).width() - rect.width), 0);
            $(linkSelector).prop('href', `${searchUrl}?q=${encodeURIComponent(selectionText)}&action=search3&lang=sk`);
            $(textPlacementSelector).html(showText);
            $(selection).attr('data-search', selectionText);
            $(selector).css('top', rect.top - document.body.getBoundingClientRect().top - 35);
            $(selector).css('left', left);
            $(selector).show();
        });
        $(closeSelector).click(() => {
            $(selector).hide();
        });
    }
    function checkAttractiveness() {
        const selector = '.js-attractiveness';
        $(selector).change(() => {
            setCategories();
        });
    }

    function init() {
        initPlace();
        initType();
        initSubject();
        initSearch();
        createModalSelect2InitTrigger();
        eventFormSelectParentCategory();
        unpackCategoryChildren();
        initUnpackCategoryChildren();
        uncheckParentCategory();
        checkAttractiveness();
        editSubject();
        initIndexSearch();
        setTimeTrigger();
        initValues();
        searchEvents();
        iframeAjaxSearch();
        initMobileBackButton();
        initBasic();
        searchOnSelect();
    }

    return {
        init,
    };
}

module.exports = selectize();
