require('jquery');
require('slick-carousel');

function slick() {
    const selector = '.js-slick';

    function homepageCarousel() {
        $(selector).each(function createSlick() {
            const prevButtonSelector = $(this).attr('data-swipe-left');
            const nextButtonSelector = $(this).attr('data-swipe-right');
            $(this).removeClass('homepage-carousel-placeholder');
            $(this).slick({
                centerMode: false,
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 4,
                variableWidth: true,
                dots: true,
                prevArrow: $(prevButtonSelector),
                nextArrow: $(nextButtonSelector),
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        },
                    },
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                    // You can unslick at a given breakpoint now by adding:
                    // settings: "unslick"
                    // instead of a settings object
                ],
                customPaging() {
                    return '<span class="slide-show-dot"></span>';
                },
            });
            $(selector).show();
        });
    }

    function ajaxCarousel() {
        const target = '.js-ajax';

        // Lister on ajax event
        $('body').on('carousel.init', target, () => {
            homepageCarousel();
        });
    }

    function detailCarousel() {
        const prevButtonSelector = '.slide-show-swipe--left';
        const nextButtonSelector = '.slide-show-swipe--right';

        $('.js-slick-detail').slick({
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            prevArrow: $(prevButtonSelector),
            nextArrow: $(nextButtonSelector),
            customPaging() {
                return '<span class="slide-show-dot"></span>';
            },
        });

        $('.js-slick-detail').find('img').each((index, element) => {
            $(element).attr('src', $(element).data('src'));
        });
    }

    function init() {
        ajaxCarousel();
        detailCarousel();
    }

    return {
        init,
    };
}

module.exports = slick();
