function removable() {
    let loading = false;
    function removableRow() {
        const selector = '.js-removable-row';

        $(document).on('click', selector, (event) => {
            const elementToRemove = $(event.target).attr('data-id');
            $(`#${elementToRemove}`).closest('li').remove();
        });
    }

    function removeAdministrator() {
        const selector = '.js-remove-administrator';

        $(document).on('click', selector, (event) => {
            if (loading) {
                return;
            }
            loading = true;
            const url = $(event.target).attr('data-url');
            const elementToRemove = $(event.target).attr('data-id');
            $(event.target).prepend('<span id="spinner" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>');
            $(event.target).attr('disabled', true);

            $.ajax({
                dataType: 'json',
                method: 'POST',
                url,
            }).done((data) => {
                if (data.removed) {
                    $(`#${elementToRemove}`).remove();
                } else {
                    $(event.target).trigger('flash-message.clear');
                    $(event.target).trigger('flash-message.show', {
                        data: {
                            message: $('#flash-messages').attr('data-administrator-not-found'),
                            type: 'danger',
                        },
                    });
                }
                $('#spinner').remove();
                $(event.target).attr('disabled', false);
                loading = false;
            });
        });
    }

    function removableEntityAjax(selector, message) {
        $(document).on('click', selector, (event) => {
            if (loading) {
                return;
            }
            loading = true;
            const elementToRemove = $(event.target).attr('data-id');
            const url = $(event.target).attr('data-url');

            $(event.target).prepend('<span id="spinner" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>');
            $(event.target).attr('disabled', true);
            $.ajax({
                method: 'POST',
                url,
            }).done(() => {
                $(`#${elementToRemove}`).remove();
                $(event.target).trigger('flash-message.clear');
                $(event.target).trigger('flash-message.show', {
                    data: {
                        message,
                        type: 'success',
                    },
                });
                $('#spinner').remove();
                $(event.target).attr('disabled', false);
                loading = false;
            });
        });
    }

    function removableFilter() {
        const selector = '.js-remove-filter';
        removableEntityAjax(selector, $('#flash-messages').attr('data-filter-deleted'));
    }

    function removableEvent() {
        const selector = '.js-remove-event';
        removableEntityAjax(selector, $('#flash-messages').attr('data-event-deleted'));
    }

    function unsetNotification() {
        const selector = '.js-unset-notification';

        $(selector).click((event) => {
            $(event.target).prepend('<span id="spinner" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>');
            $(event.target).attr('disabled', true);

            const url = $(event.target).attr('data-url');

            $.ajax({
                method: 'POST',
                url,
            }).done((data) => {
                $(event.target).closest('.js-filter-actions').html(data);
                $(event.target).trigger('flash-message.clear');
                $(event.target).trigger('flash-message.show', {
                    data: {
                        message: $('#flash-messages').attr('data-notification-removed'),
                        type: 'success',
                    },
                });
                $('#spinner').remove();
                $(event.target).attr('disabled', false);
            });
        });
    }

    function init() {
        removableRow();
        removableFilter();
        removableEvent();
        removeAdministrator();
        unsetNotification();
    }

    return {
        init,
    };
}

module.exports = removable();
