// require('jquery-ui/themes/base/tooltip.css');
// require('jquery-ui/build/release');
require('jquery-ui/ui/widgets/tooltip');

function feedToken() {
    function refreshNewFeedUrl() {
        const feedSelector = '#new_feed_url';
        const formatSelector = '#new_feed_format';
        const filterSelector = '#new_feed_filter';
        const tokenSelector = '#js-feed-token';

        let newUrl = $(tokenSelector).data('url');
        newUrl = `${newUrl}?feed_token=${$(tokenSelector).html()}&filter=${$(filterSelector).val()}&format=${$(formatSelector).val()}`;

        $(feedSelector).html(newUrl);
    }

    function generateToken() {
        const selector = '#js-generate-feed-token';
        const url = $(selector).data('url');

        if (url) {
            $(selector).click((event) => {
                $(event.target).prepend('<span id="spinner" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>');
                $(event.target).attr('disabled', true);

                $.ajax({
                    url,
                }).done((data) => {
                    $('#js-feed-token').html(data.token);
                    $('#default_feeds_table tr:last').after(data.defaultFeedRows);
                    refreshNewFeedUrl();
                    $('#my_feeds_list').show();
                    $('#spinner').remove();
                });
            });
        }
    }

    function generateFeedUrl() {
        const formatSelector = '#new_feed_format';
        const filterSelector = '#new_feed_filter';

        $(filterSelector).change(() => {
            refreshNewFeedUrl();
        });
        $(formatSelector).change(() => {
            refreshNewFeedUrl();
        });
    }

    function saveFeedUrl() {
        const selector = '#js-save-feed-url';
        const url = $(selector).data('url');

        $(selector).click((event) => {
            const feedSelector = '#new_feed_url';
            $(event.target).prepend('<span id="spinner" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>');
            $(event.target).attr('disabled', true);

            $.ajax({
                method: 'POST',
                data: { feed_url: $(feedSelector).html().replace(/&amp;/g, '&') },
                url,
                statusCode: {
                    403: () => {
                        $(event.target).attr('disabled', false);
                        $('#spinner').remove();
                        alert('Nepovolený prístup');
                    },
                    400: () => {
                        $(event.target).attr('disabled', false);
                        $('#spinner').remove();
                        alert('Chyba. Skúste obnoviť stránku');
                    },
                    200: (data) => {
                        if (data.success) {
                            $(event.target).attr('disabled', false);
                            $('#spinner').remove();
                            $('#no_feeds_message').remove();
                            $('#my_feeds_table tr:last').after(data.new_row);
                        }
                    },
                },
            });
        });
    }

    function removeFeedUrl() {
        const selector = '.js-remove-feed';

        $(document).on('click', selector, (event) => {
            $(event.target).prepend('<span id="spinner" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>');
            $(event.target).attr('disabled', true);
            const url = $(event.target).data('url');

            $.ajax({
                url,
                statusCode: {
                    403: () => {
                        $(event.target).attr('disabled', false);
                        $('#spinner').remove();
                        alert('Nepovolený prístup');
                    },
                    400: () => {
                        $(event.target).attr('disabled', false);
                        $('#spinner').remove();
                        alert('Chyba. Skúste obnoviť stránku');
                    },
                    200: (data) => {
                        if (data.success) {
                            $(event.target).attr('disabled', false);
                            $('#spinner').remove();
                            const rowTODelete = $(event.target).data('id');
                            $(rowTODelete).remove();
                        }
                    },
                },
            });
        });
    }

    function toolTips() {
        $('body').tooltip({ selector: '[data-toggle=tooltip]' });
    }

    function copyToClipboard() {
        const selector = '.js-copy-to-clipboard';

        $(selector).click((event) => {
            const $target = $($(event.target).data('target'));

            $target.select();
            document.execCommand('copy');

            $(selector).each(function () {
                $(this).html($(this).attr('data-copied-title'));
            });
        });
    }

    function init() {
        generateToken();
        generateFeedUrl();
        saveFeedUrl();
        removeFeedUrl();
        refreshNewFeedUrl();
        toolTips();
        copyToClipboard();
    }

    return {
        init,
    };
}

module.exports = feedToken();
