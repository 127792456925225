function popup() {
    function imagePopup() {
        const selector = '.js-image-popup';

        $(selector).magnificPopup({ type: 'image' });
    }

    function galleryPopup() {
        const selector = '.js-gallery-popup';

        $(selector).magnificPopup(
            {
                type: 'image',
                delegate: 'a',
                gallery: {
                    enabled: true,
                },
            },
        );
    }

    function init() {
        imagePopup();
        galleryPopup();
    }

    return {
        init,
    };
}

module.exports = popup();
