function inputImageShow() {
    function init() {
        // FORM MODAL NEW ENTITY
        const imageInputSelector = '.js-input-picture-button';

        $(document).on('change', imageInputSelector, (event) => {
            const target = $(event.target).attr('data-target');
            $(target)[0].src = URL.createObjectURL(event.target.files[0]);
        });
    }

    return {
        init,
    };
}

module.exports = inputImageShow();
