const ClassicEditor = require('@ckeditor/ckeditor5-build-classic');
require('@ckeditor/ckeditor5-build-classic/build/translations/sk');

let editorToExport;
function wysiwyg() {
    function MinHeightPlugin(editor) {
        this.editor = editor;
    }

    MinHeightPlugin.prototype.init = function addHeightProperty() {
        this.editor.ui.view.editable.extendTemplate({
            attributes: {
                style: {
                    minHeight: '150px',
                },
            },
        });
    };

    ClassicEditor.builtinPlugins.push(MinHeightPlugin);

    function init() {
        const selector = '.js-wysiwyg';
        if ($(selector).length) {
            ClassicEditor
                .create(document.querySelector(selector), {
                    toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList'],
                    language: 'sk',
                }).then((newEditor) => {
                    editorToExport = newEditor;
                    newEditor.on('ready', () => {
                });
            });
        }

        $(document).on('modal-wysiwyg.init', '.js-init-modal-trigger, .add-another-entity-into-collection-widget', () => {
            const $elements = $('#modal_skeleton').find('.js-wysiwyg');
            if ($elements.length) {
                ClassicEditor.create($elements.get()[0], {
                    toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList'],
                    language: 'sk',
                });
            }
        });
    }

    return {
        init,
    };
}
const getEditor = () => editorToExport;

module.exports = { function: wysiwyg(), editor: getEditor };
