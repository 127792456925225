function modal() {
    const bootstrap = require('bootstrap');
    let loading = false;
    let $modalSkeleton = null;

    function createModal(event, html) {
        $('#modal_skeleton').html(html);
        $(event.target).trigger('modal-select2.init');
        $(event.target).trigger('modal-date-picker.init');
        $(event.target).trigger('modal-wysiwyg.init');
        $modalSkeleton = new bootstrap.Modal($('#modal_skeleton'));
        $modalSkeleton.show();
    }

    function initModal(selector) {
        $(document).on('click', selector, (event) => {
            event.preventDefault();

            if (loading) {
                return;
            }
            $(event.target).prepend('<span id="spinner" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>');
            $(event.target).attr('disabled', true);
            loading = true;

            let url = $(event.target).attr('href');

            if (!url) {
                url = $(event.target).attr('data-url');
            }
            $.ajax({

                method: 'GET',
                url,
            }).done((data) => {
                $('#spinner').remove();
                $(event.target).attr('disabled', false);
                createModal(event, data);
                loading = false;
            });
        });
    }

    function formModalNewEntity() {
        const selector = '.add-another-entity-into-collection-widget';

        initModal(selector);
    }

    function setNotificationModal() {
        const selector = '.js-set-notification';

        initModal(selector);
    }

    function loginModal() {
        const selector = '.js-login-modal';
        $('body').on('click', selector, (event) => {
            event.preventDefault();
            $modalSkeleton.show();
        });
    }

    function initModalTrigger() {
        $('#modal_skeleton').on('hidden.bs.modal', () => {
            loading = false;
        });

        $(document).on('modal-trigger.show', '.js-init-modal-trigger', (event, params) => {
            createModal(event, params.data.html);
        });
    }

    function init() {
        if ($('#modal_skeleton').length) {
            $modalSkeleton = new bootstrap.Modal($('#modal_skeleton'));
            formModalNewEntity();
            setNotificationModal();
            loginModal();
            initModalTrigger();
        }
    }

    return {
        init,
    };
}

module.exports = modal();
