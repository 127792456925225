function sticky() {
    function init() {
        const windowWidth = $(window).width();
        const elementPosition = $('#js-search-page-button').offset();

        if (!elementPosition) {
            return;
        }
        if (windowWidth > 767) {
            $('#js-search-page-button').addClass('btn-fix');

            $(window).scroll(() => {
                const scrollHeight = $(window).height() + $(window).scrollTop();
                if (scrollHeight > elementPosition.top) {
                    $('#js-search-page-button').removeClass('btn-fix');
                } else {
                    $('#js-search-page-button').addClass('btn-fix');
                }
            });
        }
    }

    return {
        init,
    };
}

module.exports = sticky();
