// assets/js/app
const $ = require('jquery');
const bootstrap = require('bootstrap');
require('@fortawesome/fontawesome-free');
require('magnific-popup');
require('sharer.js');

const selectize = require('./selectize');
const datepicker = require('./datepicker');
const ajaxizer = require('./ajaxizer');
const removable = require('./removable');
const addLongDescription = require('./add-long-description');
const modal = require('./modal');
const addable = require('./addable');
const tabs = require('./tabs');
const previewEvent = require('./preview-event');
const collapsible = require('./collapsible');
const inlineMultichoiceTypeAddRow = require('./inline-multichoice-type-add-row');
const wysiwyg = require('./wysiwyg');
const imagePopup = require('./imagePopup');
const slick = require('./slick-carousel');
const createFlashMessage = require('./create-flash-message');
const inputImageShow = require('./input-image-show');
const facebook = require('./facebook');
const sweetAlert = require('./sweet-alert');
const feedToken = require('./feed-token');
const sticky = require('./sticky');

$(document).ready(() => {
    selectize.init();
    datepicker.init();
    ajaxizer.init();
    removable.init();
    addLongDescription.init();
    modal.init();
    addable.init();
    tabs.init();
    collapsible.init();
    inlineMultichoiceTypeAddRow.init();
    wysiwyg.function.init();
    previewEvent.init(wysiwyg.editor);
    imagePopup.init();
    slick.init();
    createFlashMessage.init();
    inputImageShow.init();
    facebook.init();
    sweetAlert.init();
    feedToken.init();
    sticky.init();

    $('-footer-menu').click((event) => {
        $(event.target).toggleClass('footer-menu-arrow-flipped');
    });

    $('#map-cities').css('height', $('#slovakia_map').height());

    if ($('#search-content').length && ('ontouchstart' in document.documentElement)) {
        window.location = '#search-content';
    }

    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl));

    // disable multiple submits
    $('form').submit(() => {
        $('input[type="submit"]').attr('disabled', 'disabled');
        return true;
    });
});
