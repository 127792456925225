function inlineMultichoiceTypeAddRow() {
    function addRow() {
        const selector = '.add-another-collection-widget';

        $(document).on('click', selector, (event) => {
            const $list = jQuery(jQuery(event.target).attr('data-list'));

            // Try to find the counter of the list

            const attr = $list.attr('data-widget-counter');

            // For some browsers, `attr` is undefined; for others, `attr` is false. Check for both.
            let counter = (typeof attr !== typeof undefined && attr !== false) ? $list.data('widget-counter')
                : $list.children().length;

            // grab the prototype template
            let newWidget = $list.attr('data-prototype');
            // replace the "__name__" used in the id and name of the prototype
            // with a number that's unique to your emails
            // end name attribute looks like name="contact[emails][2]"
            newWidget = newWidget.replace(/__name__/g, counter);
            // Increase the counter
            counter += 1;
            // And store it, the length cannot be used if deleting widgets is allowed
            $list.data(' widget-counter', counter);

            // create a new list element and add it to the list
            const newElem = jQuery($list.attr('data-widget-tags')).html(newWidget);
            newElem.appendTo($list);
            newElem.trigger('date-time.init', newElem);
        });
    }

    function init() {
        addRow();
    }

    return {
        init,
    };
}

module.exports = inlineMultichoiceTypeAddRow();
