function ajaxizer() {
    function init() {
        const selector = '.js-ajax';
        const ajaxUrl = $(selector).data('url');
        const ajaxAccept = $(selector).data('accept');
        const ajaxMethod = $(selector).data('method');
        $.ajax({
            headers: {
                Accept: ajaxAccept,
            },
            method: ajaxMethod,
            url: ajaxUrl,
        }).done((data) => {
            $(selector).html(data);
            $(selector).trigger('carousel.init');
        });
    }

    return {
        init,
    };
}

module.exports = ajaxizer();
