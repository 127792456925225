function collapsible() {
    function collapseEventFilterPanel() {
        const $toggler = $('.filter-item__chevron');

        $toggler.click((event) => {
            $(event.target).closest('.filter-item').toggleClass('item-collapsed');

            // sometimes the target is considered as the i, sometimes the wrapping span
            if ($(event.target).is('i')) {
                $(event.target).toggleClass('fa-chevron-up fa-chevron-down');
            } else {
                $(event.target).children('.fas').toggleClass('fa-chevron-up fa-chevron-down');
            }
        });
    }

    function collapseOnMobile() {
        if ($(window).width() > 768) {
            $('.filter-item').toggleClass('item-collapsed');
            $('.fa-chevron-up').toggleClass('fa-chevron-up fa-chevron-down');
        }
    }

    function init() {
        collapseEventFilterPanel();
        collapseOnMobile();
    }

    return {
        init,
    };
}

module.exports = collapsible();
