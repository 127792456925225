const moment = require('moment');

function addable() {
    function addAdministrator() {
        const selector = '#js-administrator-search-button';

        const url = $(selector).attr('data-url');
        const $emailInput = $(`#${$(selector).attr('data-id')}`);

        $(selector).click((event) => {
            if ($emailInput.val() === '') {
                $(selector).trigger('flash-message.clear');
                $(selector).trigger('flash-message.show', {
                    data: {
                        message: $('#flash-messages').attr('data-administrator-empty-value'),
                        type: 'warning',
                    },
                });
                return;
            }
            $(event.target).prepend('<span id="spinner" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>');
            $(event.target).attr('disabled', true);
            $.ajax(
                {
                    url,
                    dataType: 'json',
                    data: { email: $emailInput.val() },
                    success(data) {
                        if (data.found) {
                            if (data.alreadyAdded) {
                                $(event.target).trigger('flash-message.clear');
                                $(event.target).trigger('flash-message.show', {
                                    data: {
                                        message: $('#flash-messages').attr('data-administrator-already-added'),
                                        type: 'danger',
                                    },
                                });
                            } else {
                                $('#administrator_access tr:last').after(
                                    `<tr id="${data.id}-administrator-wrapper">
                                        <td>${$emailInput.val()}</td>
                                        <td><span data-url="${data.removeUrl}" class="js-remove-administrator fas fa-times" data-id="${data.id}-administrator-wrapper"></span></td>
                                    </tr>`,
                                );
                                $emailInput.val('');
                                $(event.target).trigger('flash-message.clear');
                                $(event.target).trigger('flash-message.show', {
                                    data: {
                                        message: $('#flash-messages').attr('data-administrator-added'),
                                        type: 'success',
                                    },
                                });
                            }
                        } else {
                            $(event.target).trigger('flash-message.clear');
                            $(event.target).trigger('flash-message.show', {
                                data: {
                                    message: $('#flash-messages').attr('data-administrator-not-found-check-email'),
                                    type: 'danger',
                                },
                            });
                        }
                        $('#spinner').remove();
                        $(event.target).attr('disabled', false);
                    },
                },
            );
        });
    }

    function addFilter() {
        const selector = '.js-save-filter';
        const $eventForm = $('#event-filter-form');
        $(selector).click((event) => {
            $(event.target).prepend('<span id="spinner" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>');
            $(event.target).attr('disabled', true);
            event.preventDefault();
            const url = $(event.target).attr('data-url');

            $.ajax({
                data: $eventForm.serialize(),
                method: 'POST',
                url,
            }).done((data) => {
                $('#js-filters-list').append(data.row);
                $(event.target).trigger('flash-message.clear');
                $(event.target).trigger('flash-message.show', {
                    data: {
                        message: $('#flash-messages').attr('data-filter-saved'),
                        type: 'success',
                    },
                });
                $(event.target).trigger('modal-trigger.show', {
                    data: {
                        html: data.modal,
                    },
                });
                $('#spinner').remove();
                $(event.target).attr('disabled', false);
            }).fail((data) => {
                if (data.status === 412) {
                    $(event.target).trigger('flash-message.clear');
                    $(event.target).trigger('flash-message.show', {
                        data: {
                            message: $('#flash-messages').attr('data-filter-not-defined'),
                            type: 'danger',
                        },
                    });
                }
                $('#spinner').remove();
                $(event.target).attr('disabled', false);
            });
        });
    }

    function addLastUsedFilter() {
        const selector = '#js-save-last-filter';
        $(selector).click((event) => {
            const url = $(selector).attr('data-url');
            $(selector).prepend('<span id="spinner" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>');
            $(selector).attr('disabled', true);

            $.ajax({
                method: 'POST',
                url,
            }).done((data) => {
                $('#spinner').remove();
                $(selector).attr('disabled', false);
                $('#js-last-used-filter-wrapper').remove();
                $('#js-no-filters-message').remove();
                $('#js-filters-list').append(data);
                $(event.target).trigger('flash-message.clear');
                $(event.target).trigger('flash-message.show', {
                    data: {
                        message: $('#flash-messages').attr('data-filter-saved'),
                        type: 'success',
                    },
                });
            }).fail((data) => {
                $('#spinner').remove();
                $(selector).attr('disabled', false);
                if (data.status === 412) {
                    $(event.target).trigger('flash-message.clear');
                    $(event.target).trigger('flash-message.show', {
                        data: {
                            message: $('#flash-messages').attr('data-filter-not-defined'),
                            type: 'danger',
                        },
                    });
                } else if (data.status === 404) {
                    $(event.target).trigger('flash-message.clear');
                    $(event.target).trigger('flash-message.show', {
                        data: {
                            message: $('#flash-messages').attr('data-last-filter-save-failure'),
                            type: 'danger',
                        },
                    });
                }
            });
        });
    }

    function updateFavoriteEvent() {
        const selector = '.js-update-favorite';
        $(document).on('click', selector, (event) => {
            event.preventDefault();
            $(event.target).parent().attr('disabled', true);
            const urlAdd = $(event.target).attr('data-url-add');
            const urlRemove = $(event.target).attr('data-url-remove');
            const url = $(event.target).hasClass('far') ? urlAdd : urlRemove;
            $.ajax({
                method: 'POST',
                url,
            }).done((data) => {
                $(event.target).parent().attr('disabled', false);
                if (data.success) {
                    $(event.target).toggleClass('far fas');
                    $(event.target).trigger('flash-message.clear');
                    $(event.target).trigger('flash-message.show', {
                        data: {
                            message: $('#flash-messages').attr('data-favorite-events-updated'),
                            type: 'success',
                        },
                    });
                } else {
                    $(event.target).trigger('flash-message.clear');
                    $(event.target).trigger('flash-message.show', {
                        data: {
                            message: $('#flash-messages').attr('data-favorite-events-not-updated'),
                            type: 'danger',
                        },
                    });
                }
            });
        });
    }

    function addFinishTime() {
        const selector = '.js-add-finish-time';

        $(document).on('click', selector, (event) => {
            const value = $($(event.target).attr('data-value-from')).find('input').val();
            const $elements = $($(event.target).attr('data-id')).find('input');
            $elements.each((index, element) => {
                $(element).daterangepicker({
                    locale: {
                        format: 'DD.M.Y H:mm',
                        separator: ' - ',
                        applyLabel: 'Vybrať',
                        cancelLabel: 'Storno',
                        fromLabel: 'Od',
                        toLabel: 'Do',
                        customRangeLabel: 'Vlastný',
                        daysOfWeek: [
                            'Ne',
                            'Po',
                            'Ut',
                            'St',
                            'Th',
                            'Št',
                            'So',
                        ],
                        monthNames: [
                            'Január',
                            'Ferbruár',
                            'Marec',
                            'Apríl',
                            'Máj',
                            'Jún',
                            'Júl',
                            'August',
                            'September',
                            'Október',
                            'November',
                            'December',
                        ],
                        firstDay: 1,
                    },
                    timePicker: true,
                    timePicker24Hour: true,
                    singleDatePicker: true,
                    showDropdowns: true,
                    minDate: value,
                    minYear: parseInt(moment().format('YYYY'), 10),
                    maxYear: parseInt(moment().format('YYYY'), 10) + 2,
                });
                $(element).val(value);
            });
            $elements.on('cancel.daterangepicker', function datePickerCancel() {
                $(this).val('');
            });

            $($(event.target).attr('data-id')).show();
            $(event.target).hide();
        });
    }

    function printFormErrorsFlashMessages(data, eventTargetElement, id = null) {
        data.forEach((value) => {
            if (typeof value.message[0] !== 'string') {
                printFormErrorsFlashMessages(value.message, eventTargetElement, value.id === 'Začiatok podujatia' || id != null ? 'Začiatok podujatia' : null);
            } else {
                $(eventTargetElement).trigger('flash-message.show', {
                    data: {
                        message: `${id != null ? id : `${value.id}`}: ${value.message}`,
                        type: 'danger',
                    },
                });
            }
        });
    }

    function addFromModal() {
        const selector = '#modal_submit_button';

        $(document).on('click', selector, (event) => {
            event.preventDefault();
            $(event.target).prepend('<span id="spinner" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>');
            $(event.target).attr('disabled', true);

            const route = $(event.target).attr('data-route');
            const fieldId = $(event.target).attr('data-field-id');
            const formData = new FormData($(`${fieldId}_modal`)[0]);

            $.ajax({
                type: 'POST',
                data: formData,
                processData: false,
                contentType: false,
                url: route,
                beforeSend() {
                },
                success(data) {
                    $('#spinner').remove();
                    $(event.target).attr('disabled', false);
                    if (data.id !== 0) {
                        const newOption = new Option(data.name, data.id, false, true);
                        $(fieldId).append(newOption).trigger('change');

                        $('#modal_skeleton').modal('hide');
                    } else {
                        printFormErrorsFlashMessages(data.errors, event.target);
                    }
                },
            });
            return false;
        });
    }

    function init() {
        addAdministrator();
        addFilter();
        addLastUsedFilter();
        updateFavoriteEvent();
        addFinishTime();
        addFromModal();
    }

    return {
        init,
    };
}

module.exports = addable();
