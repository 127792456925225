require('jquery');
require('bootstrap-daterangepicker');
const moment = require('moment');

let timeChoice = null;

function datepicker() {
    function createLocale(format) {
        return {
            format,
            separator: ' - ',
            applyLabel: 'Vybrať',
            cancelLabel: 'Storno',
            fromLabel: 'Od',
            toLabel: 'Do',
            customRangeLabel: 'Vlastný',
            daysOfWeek: [
                'Ne',
                'Po',
                'Ut',
                'St',
                'Th',
                'Št',
                'So',
            ],
            monthNames: [
                'Január',
                'Ferbruár',
                'Marec',
                'Apríl',
                'Máj',
                'Jún',
                'Júl',
                'August',
                'September',
                'Október',
                'November',
                'December',
            ],
            firstDay: 1,
        };
    }

    function initDatepicker() {
        const $element = $('.datepicker');

        $element.daterangepicker({
            locale: createLocale('DD.MM.YYYY'),
            autoUpdateInput: false,
            minDate: moment(),
        });

        $element.on('apply.daterangepicker', function datePickerApply(ev, picker) {
            $(this).html(`${picker.startDate.format('DD.MM.YYYY')} - ${picker.endDate.format('DD.MM.YYYY')}`);
            $($element.data('hidden-date-from')).val(picker.startDate.format('YYYY-MM-DD HH:mm'));
            $($element.data('hidden-date-to')).val(picker.endDate.format('YYYY-MM-DD HH:mm'));
            $($element.data('hidden-date-range-choice')).val(null);
            $(document).trigger('time-choice.set', { data: { time: `${picker.startDate.format('DD-MM-YYYY')}_${picker.endDate.format('DD-MM-YYYY')}` } });
        });

        $element.on('cancel.daterangepicker', function datePickerCancel() {
            $(this).html('Vyber dátum podujatia');
            $($element.data('hidden-date-from')).val(null);
            $($element.data('hidden-date-to')).val(null);
            $($element.data('hidden-date-range-choice')).val(null);
            $(document).trigger('time-choice.set', { data: { time: null } });
        });

        if ($('#date-range-choice').val()) {
            const time = $('#date-range-choice').val();
            $(document).trigger('time-choice.set', { data: { time } });
        } else if ($('#date-from').val()) {
            const dateFrom = $('#date-from').val();
            const dateTo = $('#date-to').val();
            $(document).trigger('time-choice.set', { data: { time: `${dateFrom}_${dateTo}` } });
        }
    }

    function initFillers() {
        const $elements = $('.js-fill-date');

        $elements.click(function jsFillClick(event) {
            event.preventDefault();
            const target = $(this).data('target');
            const hiddenTarget = $(this).data('hidden-target');
            const $targetElement = $(target);
            timeChoice = $(this).attr('data-date-range-choice');
            $(hiddenTarget).val(timeChoice);
            $targetElement.html($(this).text());
            $(document).trigger('time-choice.set', { data: { time: timeChoice } });
        });
    }

    function initModalDatePickerTrigger() {
        $(document).on('modal-date-picker.init', '.js-init-modal-trigger, .js-set-notification', () => {
            const $select = $('#modal_skeleton').find('.datepicker');
            $select.each(function createModalDatepicker() {
                $(this).daterangepicker({
                    locale: createLocale('DD.MM.YYYY'),
                    autoUpdateInput: false,
                    minDate: moment(),
                });

                $(this).on('apply.daterangepicker', function datePickerApply(ev, picker) {
                    $(this).html(`${picker.startDate.format('DD.MM.YYYY')} - ${picker.endDate.format('DD.MM.YYYY')}`);
                    $($select.data('hidden-date-from')).val(picker.startDate.format('YYYY-MM-DD HH:mm'));
                    $($select.data('hidden-date-to')).val(picker.endDate.format('YYYY-MM-DD HH:mm'));
                    $($select.data('hidden-date-range-choice')).val(null);
                });

                $(this).on('cancel.daterangepicker', function datePickerCancel() {
                    $(this).html('');
                });
            });
            initFillers();
        });
    }

    function customDatePopup() {
        const selector = '.js-popup-date-range-picker';

        $(selector).click((event) => {
            const datepickerSelector = $(event.target).attr('data-target');
            $(datepickerSelector).trigger('click');
        });
    }

    function createDateTimePicker($elements, minDate = null) {
        $elements.each((index, element) => {
            const initValue = $(element).val();
            $(element).daterangepicker({
                locale: createLocale('DD.M.Y H:mm'),
                timePicker: true,
                timePicker24Hour: true,
                singleDatePicker: true,
                showDropdowns: true,
                minDate: minDate === null ? moment() : minDate,
                minYear: parseInt(moment().format('YYYY'), 10),
                maxYear: parseInt(moment().format('YYYY'), 10) + 2,
            });

            const date1 = moment(initValue, 'DD.M.Y H:mm');
            const date2 = moment(minDate, 'DD.M.Y H:mm');

            const greaterDate = date1.diff(date2) > 0 ? initValue : minDate;
            $(element).val(minDate === null ? initValue : greaterDate);
            $(element).on('apply.daterangepicker', (ev) => {
                if ($($(element).attr('data-target')).is(':visible')) {
                    createDateTimePicker($($(element).attr('data-target')), $(ev.target).val());
                }
            });
        });
        $elements.on('cancel.daterangepicker', function datePickerCancel() {
            $(this).val('');
        });
    }

    function initDateTimePicker() {
        const $elements = $('.js-date-time-picker');

        createDateTimePicker($elements);
    }

    function dateTimeTrigger() {
        $(document).on('date-time.init', (event, newElement) => {
            const $elements = $(newElement).find('.js-date-time-picker');

            createDateTimePicker($elements);
        });
    }

    function init() {
        initDatepicker();
        initFillers();
        initDateTimePicker();
        dateTimeTrigger();
        initModalDatePickerTrigger();
        customDatePopup();
    }

    return {
        init,
    };
}

module.exports = datepicker();
