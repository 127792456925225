const swal = require('sweetalert2');

function sweetAlert() {
    function deleteAlert() {
        const selector = '.js-sweet-alert-target';

        $(selector).click((event) => {
            event.preventDefault();
            const positive = $(event.target).attr('data-positive');
            const negative = $(event.target).attr('data-negative');
            const alertTitle = $(event.target).attr('data-title');
            const alertText = $(event.target).attr('data-text');

            swal.fire({
                title: alertTitle,
                text: alertText,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: positive,
                cancelButtonText: negative,
            }).then((result) => {
                if (result.value) {
                    $.ajax({
                        url: $(event.target).attr('data-url'),
                        method: 'DELETE',
                        success: function () {
                            window.location = $(event.target).attr('data-redirect')
                        }
                    });
                }
            });
        });
    }

    function init() {
        deleteAlert();
    }

    return {
        init,
    };
}

module.exports = sweetAlert();
