function tabs() {
    function initTabs() {
        const selector = '.tab-link';
        const tabsContentSelector = '.tab-content';

        $(selector).click(function buttonOpenTab() {
            $(selector).removeClass('active');
            $(tabsContentSelector).hide();
            $(this).addClass('active');
            $(`#${$(this).attr('data-tab')}`).show();
        });
    }

    function validateTab(selector) {
        let valid = true;

        const groups = [];

        $(`${selector} :input`).each(function validateTabInputs() {
            if (!this.checkValidity()) {
                if ($(this).attr('type') === 'radio') {
                    if (groups.includes($(this).attr('name'))) {
                        return;
                    }
                    groups.push($(this).attr('name'));
                }

                if ($(this).hasClass('select2-hidden-accessible')) {
                    $(this).data('select2').$container.find('.select2-selection').addClass('form-input-error');
                }

                valid = false;
                if (!$(this).hasClass('is-invalid')) {
                    $(this).addClass('is-invalid');
                    $(this).closest('.form-group').append('<span class="help-block"><ul class="list-unstyled"><li><span class="glyphicon glyphicon-exclamation-sign"></span>Táto hodnota by mala byť správne vyplnená.</li></ul></span>');
                }
            } else {
                if ($(this).hasClass('select2-hidden-accessible')) {
                    $(this).data('select2').$container.find('.select2-selection').removeClass('form-input-error');
                }
                $(this).removeClass('is-invalid');
                $(this).closest('.form-group').find('.help-block').remove();
            }
        });

        return valid;
    }

    function openTab(event) {
        if (validateTab('.form_group_modal:visible')) {
            let $tabContent = $('.form_group_modal');
            for (let i = 0; i < $tabContent.length; i += 1) {
                $tabContent[i].style.display = 'none';
            }

            $tabContent = $('.form_group_modal_footer');
            for (let i = 0; i < $tabContent.length; i += 1) {
                $tabContent[i].style.display = 'none';
            }

            // Show the current tab, and add an "active" class to the button that opened the tab
            const id = $(event.target).attr('data-id');
            document.getElementById(id).style.display = 'block';
            document.getElementById(`${id}_footer`).style.display = 'block';
        }
    }

    function initModalTabs() {
        const selector = '.js-modal-tablink';

        $(document).on('click', selector, (event) => {
            event.preventDefault();
            openTab(event);
        });
    }

    function init() {
        initTabs();
        initModalTabs();
    }

    return {
        init,
    };
}

module.exports = tabs();
