const bootstrap = require('bootstrap');

function previewModal() {
    let loading = false;

    $('#event-preview-modal-skeleton').on('hidden.bs.modal', () => {
        loading = false;
    });

    function init(editor) {
        // FORM MODAL NEW ENTITY
        const $modalLink = $('#event-preview');

        function loadFile($input, $output, $preview) {
            if ($input.find('input[type="checkbox"]').is(':checked')) {
                return false;
            }

            if ($preview.attr('src')) {
                $output.attr('src', $preview.attr('src'));
                return true;
            }

            if ($input.find('input[type=file]')[0].files[0]) {
                $output[0].src = URL.createObjectURL($input.find('input[type=file]')[0].files[0]);
            } else {
                $output.remove();
            }
            return true;
        }

        function loadGalleryFiles() {
            const gallerySelector = '#js-preview-gallery';
            const galleryPictureSelector = '.js-input-gallery-picture';
            const times = $(galleryPictureSelector).length;
            const $mainPictureInput = $('.js-input-main-picture');
            const $mainPicturePreview = $('#event_mainPicture_event_mainData_mainPicture_image_preview');
            let loaded;

            $(gallerySelector).append(`
                            <a href="#">
                                <img class="kapo-detail-galery-image" src="" id="gallery_image_main"
                                     data-src="{{ event.image }}"
                                     alt="" loading="lazy">
                            </a>`);
            loaded = loadFile($mainPictureInput, $('#gallery_image_main'), $mainPicturePreview);
            if (!loaded) {
                $('#gallery_image_main').parent().remove();
            }
            let actualTimes = times;
            for (let i = 0; i < times; i++) {
                $(gallerySelector).append(`
                            <a href="#">
                                <img class="kapo-detail-galery-image" src="" id="gallery_image_${i}"
                                     data-src=""
                                     alt="" loading="lazy">
                            </a>`);
                const $input = $(`${galleryPictureSelector}:eq(${i})`);
                const $output = $(`#gallery_image_${i}`);
                const $preview = $(`#galleryItems_media_event_mainData_galleries_0_galleryItems_${i}_media_image_preview`);
                loaded = loadFile($input, $output, $preview);
                if (!loaded) {
                    actualTimes--;
                    $output.parent().remove();
                }
            }
            if (actualTimes === 0) {
                // no gallery images were loaded
                $('#preview-gallery-wrapper').remove();
                $('#js-preview-main-picture').append(`
                            <a href="#">
                                <img class="kapo-detail-galery-image" src="" id="image_main"
                                     data-src="{{ event.image }}"
                                     alt="" loading="lazy">
                            </a>`);
                loaded = loadFile($mainPictureInput, $('#image_main'), $mainPicturePreview);
                if (!loaded) {
                    $('#preview-main-picture-wrapper').remove();
                }
                $('#preview-gallery-wrapper').remove();
            } else {
                $('#preview-main-picture-wrapper').remove();
            }
        }

        function printFormErrorsFlashMessages(data, eventTargetElement, id = null) {
            for (const key of Object.keys(data)) {
                if (typeof data[key].message[0] !== 'string') {
                    printFormErrorsFlashMessages(data[key].message, eventTargetElement, data[key].id === 'Začiatok podujatia' || id != null ? 'Začiatok podujatia' : null);
                } else {
                    $(eventTargetElement).trigger('flash-message.show', {
                        data: {
                            message: `${id != null ? id : `${data[key].id}`}: ${data[key].message}`,
                            type: 'danger',
                        },
                    });
                }
            }
        }

        $modalLink.click(function modalLinkClick(e) {

            const $button = $(this);
            $button.prepend('<span id="spinner" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>');
            $button.attr('disabled', true);
            e.preventDefault();
            $(e.target).trigger('flash-message.clear');

            if (loading) {
                return;
            }
            loading = true;
            const url = $(this).attr('data-url');
            const $eventForm = $('#event-form');
            $('#event_mainData_longDescription').html(editor().getData());

            $(document).on('shown.bs.modal', () => {
                const selector = '.js-slick-detail';
                const prevButtonSelector = '.slide-show-swipe--left';
                const nextButtonSelector = '.slide-show-swipe--right';
                $(selector).slick({
                    infinite: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    prevArrow: $(prevButtonSelector),
                    nextArrow: $(nextButtonSelector),
                    customPaging() {
                        return '<span class="slide-show-dot"></span>';
                    },
                });
            });

            $.ajax({
                data: $eventForm.serialize(),
                method: 'POST',
                url,
            }).done((data) => {

                $('#event-preview-modal-skeleton').html(data);
                loading = false;
                const $logoInput = $('.js-input-logo');
                const $logoPreview = $('#event_logo_event_mainData_logo_image_preview');
                const $logoOutput = $('#js-logo-preview');

                const loaded = loadFile($logoInput, $logoOutput, $logoPreview);
                if (!loaded) {
                    $('.kapo-detail-preview').remove();
                }
                loadGalleryFiles();
                const $modalSkeleton = new bootstrap.Modal($('#event-preview-modal-skeleton'));
                $modalSkeleton.show();

                $('#spinner').remove();
                $(this).attr('disabled', false);
            }).fail((data) => {
                loading = false;
                $('#spinner').remove();
                $button.attr('disabled', false);
                if (data.status === 412) {
                    const errors = data.responseJSON;
                    printFormErrorsFlashMessages(errors, e.target);
                    window.scrollTo(0, 0);
                }
                $('#spinner').remove();
                $(this).attr('disabled', false);
            });
        });
    }

    return {
        init,
    };
}

module.exports = previewModal();
