function addLongDescription() {
    function init() {
        const longDescriptionSelector = '.add_long_description';

        $(document).on('click', longDescriptionSelector, (e) => {
            e.preventDefault();
            const elementToShow = $(e.target).attr('data-id');
            $(`#${elementToShow}`).show();
            $(e.target).hide();
        });
    }

    return {
        init,
    };
}

module.exports = addLongDescription();
